<template>
	<div>
		<b-row>
			<b-col cols="4">
				<p style="font-size: 16px; font-weight: bolder">{{ FormMSG(1, 'List of tasks') }}</p>
			</b-col>
			<b-col cols="3">
				<p style="font-size: 16px; font-weight: bolder">{{ FormMSG(2, 'List users and teams') }}</p>
			</b-col>
			<b-col cols="3">
				<p style="font-size: 16px; font-weight: bolder">{{ FormMSG(35, 'For dates') }}</p>
			</b-col>
			<b-col cols="2" />
		</b-row>
		<b-row>
			<b-col cols="4" style="border-right: 1px solid rgba(0, 0, 0, 0.125)">
				<list-task ref="assignmentsListTask" :tasks="tasks" @list-task:selected="handleListTaskSelected" />
			</b-col>
			<b-col cols="3" style="border-right: 1px solid rgba(0, 0, 0, 0.125)">
				<list-users key="assignments-list-users" :users="users" :task="taskSelected" @list-users:selected="handleListUsersSelected" />
				<list-teams key="assignments-list-teams" :teams="teams" :task="taskSelected" @list-teams:selected="handleListTeamsSelected" />
			</b-col>
			<b-col cols="3">
				<list-plannings :list-plannings="listPlannings" @list-planning:selected="handleListPlanningSelected" />
			</b-col>
			<b-col cols="2">
				<b-button variant="success" block :disabled="enableBtnAssign" @click="handleClickBtnAssign">
					{{ FormMSG(4, 'Assign') }}
				</b-button>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { getPlannings, assigneeMultiple } from '@/cruds/task.crud';
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
import GlobalMixin from '@/mixins/global.mixin';
import ListTeams from '@/components/AssignationMultiple/ListTeams';
import ListUsers from '@/components/AssignationMultiple/ListUsers';
import ListPlannings from '@/components/AssignationMultiple/ListPlannings';
import moment from 'moment';
import ListTask from './ListTask';
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'Assignements',

	components: {
		PerfectScrollbar,
		ListTeams,
		ListUsers,
		ListPlannings,
		ListTask
	},

	mixins: [GlobalMixin, languageMessages],

	props: {
		tasks: { type: Array, default: () => [] },
		users: { type: Array, default: () => [] },
		teams: { type: Array, default: () => [] }
	},

	data() {
		return {
			listPlannings: [],
			taskSelected: null,
			usersSelected: [],
			teamsSelected: [],
			planningsSelected: [],
			currentFormatDate: 'dddd, MMMM Do YYYY'
		};
	},

	computed: {
		enableBtnAssign() {
			if (this.planningsSelected.length > 0 && (this.usersSelected.length > 0 || this.teamsSelected.length > 0)) {
				return false;
			}

			return true;
		}
	},

	methods: {
		async handleClickBtnAssign() {
			const action = async () => {
				const inputForUser = await this.prepareDataToSend('user');
				const inputForTeam = await this.prepareDataToSend('team');
				if (inputForUser.length > 0) {
					await assigneeMultiple(inputForUser);
				}
				if (inputForTeam.length > 0) {
					await assigneeMultiple(inputForTeam);
				}

				this.initData();

				await this.$bvToast.toast(this.FormMSG(5, 'Assignements added successfully'), {
					title: 'Success',
					variant: 'success',
					solid: true,
					toaster: 'b-toaster-top-center'
				});
			};

			await this.confirmModal(action, this.FormMSG(6, 'Are you sure ?'));
		},
		handleListPlanningSelected(payload) {
			this.planningsSelected = payload.plannings;
		},
		async handleListTeamsSelected(payload) {
			this.teamsSelected = payload.teamsSelected;
		},
		async handleListUsersSelected(payload) {
			this.usersSelected = payload.usersSelected;
		},
		async handleListTaskSelected(task) {
			this.taskSelected = task;
			await this.getPlannings(task);
		},
		async getPlannings(task) {
			this.planningsSelected = [];
			this.listPlannings = await getPlannings(task.id);
			this.listPlannings.map((lP) => {
				const date = new Date(lP.desiredStart);
				lP.desiredStart = new Date(moment(date).format('YYYY-MM-DD') + 'T00:00:00Z');
				lP.desiredStartFormatting = moment(date).format(this.currentFormatDate);
				lP.isChecked = false;
			});
		},
		initData() {
			this.taskSelected = null;
			this.planningsSelected = [];
			this.usersSelected = [];
			this.teamsSelected = [];
			this.listPlannings = [];
			this.$refs.assignmentsListTask.initData();
		},
		async prepareDataToSend(type = 'user') {
			let dataToSend = [];
			if (type === 'user') {
				if (this.usersSelected.length > 0) {
					for (let i = 0; i < this.planningsSelected.length; i++) {
						const planning = this.planningsSelected[i];
						for (let j = 0; j < this.usersSelected.length; j++) {
							const user = this.usersSelected[j];
							dataToSend.push({
								taskPlanningId: parseInt(planning.id, 10),
								userId: parseInt(user.id, 10),
								teamsId: 0
							});
						}
					}
				}
			} else {
				if (this.teamsSelected.length > 0) {
					for (let i = 0; i < this.planningsSelected.length; i++) {
						const planning = this.planningsSelected[i];
						for (let j = 0; j < this.teamsSelected.length; j++) {
							const team = this.teamsSelected[j];
							dataToSend.push({
								taskPlanningId: parseInt(planning.id, 10),
								teamId: parseInt(team.id, 10),
								userId: 0
							});
						}
					}
				}
			}

			return dataToSend;
		},
		checkLanguage() {
			moment.locale(this.lang);
		}
	}
};
</script>

<style scoped></style>
